import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import { numberWithCommas } from "@/utils/utils";
import { BottomSheet } from "@components/index";
import { useDispatch } from "react-redux";
import * as APIS from "../../../services/apis";
import { showToast } from "@/features/toast/toastSlice";
import imgCaution from "../../../assets/images/img_caution_rd.png";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import usePendingCheck from "@/hooks/usePendingCheck";
import * as styles from "../styles.css";
import { useState } from "react";

interface DeleteModalProps {
  assetType?: string;
  tradeType?: string;
  orderType?: string;
  orderPrice?: string;
  //   totalPrice?: string;
  requestGram?: string;
  remainGram?: string;
  deleteId?: number;
  handleClickRefresh: Function;
}

interface IConfirmPointResponse {
  success: boolean;
  message: string;
  data: null;
}

const DeleteModal = ({
  assetType,
  tradeType,
  orderType,
  orderPrice,
  //   totalPrice,
  requestGram,
  remainGram,
  deleteId,
  handleClickRefresh,
}: DeleteModalProps) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const assetTypeName = assetType
    ?.replace("GOLD", "금")
    ?.replace("SILVER", "은");
  const orderTypeName = orderType?.replace("LIMITS", "지정가");
  const pendingCheck = usePendingCheck();
  const handleClickClose = () => {
    dispatch(closeBottomSheet());
  };

  const pendingDeleteConfirm = (deleteId: any) => {
    pendingCheck<IConfirmPointResponse>({
      checkFn: () => APIS.getDeleteBuySell(deleteId),
      successCondition: () => true,
      successMessage: "주문이 취소되었습니다.",
      processingMessage: "주문 취소 요청 중 입니다.",
      failureMessage: "주문 취소에 실패하였습니다.",
      navigateOnSuccess: () => {
        dispatch(closeBottomSheet());
        handleClickRefresh();
        setIsSubmitting(false);
      },
      navigateOnProcessing: () => {
        dispatch(closeBottomSheet());
        handleClickRefresh();
        setIsSubmitting(false);
      },
      navigateOnFailure: () => {
        dispatch(closeBottomSheet());
        setIsSubmitting(false);
      },
    });
  };

  const cancelOrder = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const serviceBreak = await APIS.getServiceBreak();
    if (serviceBreak) {
      dispatch(showToast({ message: serviceBreak, icon: "caution" }));
      return;
    }

    APIS.deleteBuySell(deleteId)
      .then(({ data: { success, message } }) => {
        if (success) {
          pendingDeleteConfirm(deleteId);
        } else {
          dispatch(showToast({ message, icon: "error" }));
        }
      })
      .catch(() => {
        dispatch(showToast({ message: "요청이 실패했습니다.", icon: "error" }));
      });
  };

  return (
    <BottomSheet>
      <div className="sub_bottom rv_tab receipt_tab02">
        <div className="sub_bottom_wrap">
          <img src={imgCaution} alt="" />
          <div className="receipt_tit">
            <h3>
              <span className="fc-rd01">{`${
                tradeType === "SELL" ? "매도" : "매수"
              } 주문`}</span>
              을<span className="fc-rd01"> 취소</span>하시겠어요?
            </h3>
            <p className="fc-gr01">
              <span>미체결된 중량</span>만 <span>취소</span>됩니다.
            </p>
          </div>
          <div className="receipt_wrap">
            <div className="receipt_top">
              <ul className="receipt_ul01">
                <li className="receipt_left">주문 상품</li>
                <li className="receipt_right">
                  <span>{assetTypeName}</span>
                </li>
              </ul>
              <ul className="receipt_ul01">
                <li className="receipt_left">주문 방식</li>
                <li className="receipt_right">
                  <span>{orderTypeName}</span>
                </li>
              </ul>
              <ul className="receipt_ul01">
                <li className="receipt_left">
                  {`${tradeType === "SELL" ? "매도" : "매수"} 가격`}
                  {/* 가격 */}
                </li>
                <li className="receipt_right">
                  <span>{orderPrice ? numberWithCommas(orderPrice) : "0"}</span>
                  원
                </li>
              </ul>
              {/* <ul className="receipt_ul01">
                <li className="receipt_left">주문 금액</li>
                <li className="receipt_right">
                  <span>{totalPrice ? numberWithCommas(totalPrice) : "0"}</span>
                  원
                </li>
              </ul> */}
            </div>
            <div className="receipt_bottom">
              <ul className="receipt_ul01">
                <li className="receipt_left">
                  <span
                    className={`${
                      tradeType === "SELL" ? "fc-bl01" : "fc-rd01"
                    }`}
                  >
                    주문 중량
                  </span>
                </li>
                <li className="receipt_right">
                  <p
                    className={`${
                      tradeType === "SELL" ? "fc-bl01" : "fc-rd01"
                    }`}
                  >
                    <span>
                      {requestGram ? numberWithCommas(requestGram) : "0"}
                    </span>
                    g
                  </p>
                </li>
              </ul>
              <ul className="receipt_ul01">
                <li className="receipt_left">
                  <p className="fc-bk01">
                    <span>미체결 중량</span>
                  </p>
                </li>
                <li className="receipt_right">
                  <p className="fc-bk01">
                    <span>
                      {remainGram ? numberWithCommas(remainGram) : "0"}
                    </span>
                    g
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.receiptTab02}>
          <button
            className={`${styles.receiptTabBtn} ${styles.receiptTabCloseBtn}`}
            onClick={handleClickClose}
          >
            닫기
          </button>
          <button
            className={`${styles.receiptTabBtn} ${styles.receiptTabCancelBtn}`}
            onClick={cancelOrder}
            disabled={isSubmitting}
          >
            주문 취소
          </button>
        </div>
      </div>
    </BottomSheet>
  );
};

export default DeleteModal;
