import { useEffect, useState } from "react";
import IconLeft from "@assets/icons/icon_left.png";
import IconRight from "@assets/icons/ico_arrow_right.png";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import { useDispatch } from "react-redux";
import AcceptTermsDetail from "./components/AcceptTermsDetail";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import * as APIS from "@/services/apis";
import { saveSignupStep, signIn } from "@/utils/sign";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "@/features/toast/toastSlice";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import { updateSignUp } from "@/features/auth/signUpSlice";
import { routes } from "@/routes";
import { BottomSheet } from "@components/index";

interface Term {
  type: string;
  signup: boolean;
  title: string;
  required: boolean;
  checked?: boolean;
}
/**
 * 프로세스
 기존 :
 약관 동의 > 가입경로(추천 대리점, 대리점 선택) > 이메일 입력 > 결제 비밀번호 입력

 수정 :
 약관 동의 > 이메일 입력 > 가입경로 > 결제 비밀번호 입력
 */

const AcceptTerms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { purpose = "", token = "" } = state || {};
  const [terms, setTerms] = useState<Term[]>([]);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [required, setRequired] = useState<boolean>(true);
  const { termsTypes = [], purpose: termsPurpose } = useSelector(
    (state: RootState) => state.signUp,
  );

  useEffect(() => {
    // const params = purpose === "MALL_TO_APP" ? { isMallToApp: true } : {};
    APIS.getTerms().then(({ data: { success, data = [] } }) => {
      if (success) {
        const filtered = data.filter((item: Term) => item.signup);
        setTerms(
          filtered.map((item: Term) => {
            const checked = termsTypes.includes(item.type);
            return { ...item, checked };
          }),
        );
      }
    });
    if (termsPurpose === "SIGNUP") saveSignupStep("TERMS");
  }, []);

  const handleClickDetail = (
    type: string,
    title: string,
    required: boolean,
  ) => {
    setSelectedType(type);
    setTitle(title);
    setRequired(required);
    dispatch(openBottomSheet());
  };

  const register = () => {
    const termsTypes = terms
      .filter(({ checked }) => checked)
      .map(({ type }) => type);
    if (canNext) {
      if (purpose === "MALL_TO_APP") {
        dispatch(showLoading());
        APIS.postMallToApp({ termsTypes }, token)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              signIn(data);
              navigate(routes.main, { replace: true });
              // dispatch(
              //   showToast({ message: "로그인 되었습니다.", icon: "success" }),
              // );
            } else {
              dispatch(showToast({ message, icon: "error" }));
              navigate(-1);
            }
          })
          .finally(() => {
            dispatch(hideLoading());
          });
      } else {
        dispatch(updateSignUp({ termsTypes }));
        navigate(routes.authRegisterEmail, { replace: true });
      }
    }
  };
  const handleClickAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setTerms((prevTerms) => prevTerms.map((term) => ({ ...term, checked })));
  };

  const handleClickCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number,
  ) => {
    const checked = event.target.checked;
    const item = terms[i];
    if (item.checked !== checked) {
      const copy = [...terms];
      copy.splice(i, 1, { ...item, checked });
      setTerms(copy);
    }
  };

  const handleConfirmDetail = () => {
    if (selectedType !== null) {
      const updatedTerms = terms.map((term) =>
        term.type === selectedType ? { ...term, checked: true } : term,
      );
      setTerms(updatedTerms);
    }
    dispatch(closeBottomSheet());
  };

  const canNext =
    terms.findIndex(({ checked, required }) => required && !checked) === -1;
  const allCheck = terms.every(({ checked }) => checked);

  return (
    <div>
      <div className="sub_top03">
        <div>
          <img
            src={IconLeft}
            alt="back"
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>약관 동의</p>
        </div>
      </div>
      <div className="sub_wrap08_2">
        <h3 className="sub_wrap08_2_tt">
          금방금방 이용을 위해 <br />
          약관에 동의해주세요
        </h3>
        <div>
          <div className="terms_check check_all">
            <input
              type="checkbox"
              id="all-check"
              name="term_check"
              checked={allCheck}
              onChange={handleClickAll}
            />
            <label htmlFor="all-check">
              <h3>약관 전체 동의 (선택 동의 포함)</h3>
            </label>
          </div>
          {terms.map(({ type, title, required, checked }, i) => {
            return (
              <div className="terms_check" key={i}>
                <input
                  type="checkbox"
                  id={`check${i}`}
                  name="term_check"
                  checked={checked}
                  onChange={(checked) => handleClickCheck(checked, i)}
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor={`check${i}`}
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    if (
                      target.tagName !== "INPUT" &&
                      target.tagName !== "LABEL"
                    ) {
                      event.preventDefault(); // 체크박스 상태 변경 방지
                      handleClickDetail(type, title, required);
                    }
                  }}
                >
                  <p>
                    <span className={`${required ? "fc-bl03" : ""}`}>
                      {`${required ? "[필수]" : "[선택]"}`}{" "}
                    </span>
                    {title}
                    <img src={IconRight} />
                  </p>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="sub08_btn">
        <button
          className={`${canNext ? "on" : "off"}`}
          onClick={() => register()}
        >
          확인
        </button>
      </div>
      <BottomSheet>
        <AcceptTermsDetail
          selectedType={selectedType}
          title={title}
          required={required}
          onConfirm={handleConfirmDetail}
        />
      </BottomSheet>
    </div>
  );
};

export default AcceptTerms;
